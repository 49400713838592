import React from "react";
import Card from "components/Card";
import Typography from "@material-ui/core/Typography";
import {
  AutocompleteQuestion,
  MultiSelectQuestion,
  UploadQuestion,
  YesNoQuestion,
  TextQuestion
} from "../helpers";
import { pricing } from "constants/pricing";

export default class RMTForm extends React.Component {
  render() {
    const { values, visible } = this.props;

    let newGrad =
      this.props.values.RMT.selection === "newGradRMT" ||
      this.props.values.RMT.selection === "newGradRMTAcupuncture";

    let isProvinceNewBrunswickOrPEI =false;
    let isProvinceSaskatchewan = false;
    switch(values.applicantInformation.province){
      case "New Brunswick":{
        isProvinceNewBrunswickOrPEI = true;
        break;
      }
      case "Prince Edward Island":{
        isProvinceNewBrunswickOrPEI = true;
        break;
      }
      case "Saskatchewan":{
        isProvinceSaskatchewan = true;
        break;
      }
      default: break;
    }

    // determine if the pricing is for new business or old, and what the effective date is
    let effectiveDate = new Date().setHours(24, 0, 0, 0); // next midnight
    if (values.applicantInformation.newPolicyyes_no === "yes" && values.applicantInformation.expiring_policydifferentDate != "") {
      effectiveDate = new Date(values.applicantInformation.expiring_policydifferentDate).setHours(24, 0, 0, 0);
    }
    let thisPricing;

    // knowing the effective date, go backwards through the pricing array to find the pricing that matches
    // pricing in array is epoch time, so multiply by 1000 to get javascript version
    for (let i = pricing.length - 1; i >= 0; i--) {
      if (values.applicantInformation.newPolicyyes_no === "yes") {
        if (effectiveDate > pricing[i].effective.new * 1000) {
          if (values.applicantInformation.province === "British Columbia" || isProvinceNewBrunswickOrPEI) {
            thisPricing = pricing[i].rmtBC;
          } else if(isProvinceSaskatchewan){
            thisPricing = pricing[i].rmtSK;
          } else {
            thisPricing = pricing[i].vals;
          }
          break;
        }
      } else {
        if (effectiveDate > pricing[i].effective.renewal * 1000) {
          if (values.applicantInformation.province === "British Columbia" || isProvinceNewBrunswickOrPEI) {
            thisPricing = pricing[i].rmtBC;
          } else if(isProvinceSaskatchewan){
            thisPricing = pricing[i].rmtSK;
          } else {
            thisPricing = pricing[i].vals;
          }
          break;
        }
      }
    }

    // we are compiling the list of policy options before displaying in order to avoid those which are not (or were not) available
    let rmtOptions = [];
    if (thisPricing.annualRMT !== 0) {
      rmtOptions.push({
        option: "$" + thisPricing.annualRMT.toFixed(2) + " Annual RMT Policy",
        value: "annualRMT"
      });
    }
    if (thisPricing.sixMonthRMT !== 0) {
      rmtOptions.push({
        option: "$" + thisPricing.sixMonthRMT.toFixed(2) + " Six Month RMT Policy",
        value: "sixMonthRMT"
      });
    }
    if (thisPricing.newGradRMT !== 0) {
      rmtOptions.push({
        option: "$" + thisPricing.newGradRMT.toFixed(2) + " New Graduate RMT Policy",
        value: "newGradRMT"
      });
    }
    if (thisPricing.annualRMTAcupuncture !== 0) {
      rmtOptions.push({
        option: "$" + thisPricing.annualRMTAcupuncture.toFixed(2) + " Annual RMT + Acupuncture within scope of RMT as registered with the CMTO",
        value: "annualRMTAcupuncture"
      });
    }
    if (thisPricing.newGradRMTAcupuncture !== 0) {
      rmtOptions.push({
        option: "$" + thisPricing.newGradRMTAcupuncture.toFixed(2) + " New Graduate RMT + Acupuncture within scope of RMT as registered with the CMTO",
        value: "newGradRMTAcupuncture"
      });
    }
    if (thisPricing.sixMonthRMTAcupuncture !== 0) {
      rmtOptions.push({
        option: "$" + thisPricing.sixMonthRMTAcupuncture.toFixed(2) + " Six Month RMT + Acupuncture within scope of RMT as registered with the CMTO",
        value: "sixMonthRMTAcupuncture"
      });
    }
    if (thisPricing.annualRMTOsteopathy !== 0) {
      rmtOptions.push({
        option:
          "$" + thisPricing.annualRMTOsteopathy.toFixed(2) + " Annual RMT + Osteopathy/Osteopathy Student Policy",
        value: "annualRMTOsteopathy"
      });
    }
    if (thisPricing.sixMonthRMTOsteopathy !== 0) {
      rmtOptions.push({
        option: "$" + thisPricing.sixMonthRMTOsteopathy.toFixed(2) + " Six Month RMT + Osteopathy/Osteopathy Student Policy",
        value: "sixMonthRMTOsteopathy"
      });
    }
    if (thisPricing.annualRMTOsteopathyAcupuncture !== 0) {
      rmtOptions.push({
        option:
          "$" + thisPricing.annualRMTOsteopathyAcupuncture.toFixed(2) + " Annual RMT + Osteopathy + Acupuncture within scope of RMT as registered with the CMTO",
        value: "annualRMTOsteopathyAcupuncture"
      });
    }
    if (thisPricing.sixMonthRMTOsteopathyAcupuncture !== 0) {
      rmtOptions.push({
        option: "$" + thisPricing.sixMonthRMTOsteopathyAcupuncture.toFixed(2) + " Six Month RMT + Osteopathy + Acupuncture within scope of RMT as registered with the CMTO",
        value: "sixMonthRMTOsteopathyAcupuncture"
      });
    }
    if (thisPricing.annualRMTPT1 !== 0) {
      rmtOptions.push({
        option:
          "$" + thisPricing.annualRMTPT1.toFixed(2) + "  Annual RMT + Personal Trainer – option 1 – weight and ratio restriction (No more than 2 clients at a time. Weights cannot exceed 80lbs. Signed Client Waivers Required)",
        value: "annualRMTPT1"
      });
    }
    if (thisPricing.sixMonthRMTPT1 !== 0) {
      rmtOptions.push({
        option:
          "$" + thisPricing.sixMonthRMTPT1.toFixed(2) + " Six Month RMT + Personal Trainer  – option 1 – weight and ratio restriction (No more than 2 clients at a time. Weights cannot exceed 80lbs. Signed Client Waivers Required)",
        value: "sixMonthRMTPT1"
      });
    }
    if (thisPricing.annualRMTPT2 != 0) {
      rmtOptions.push({
        option:
          "$" + thisPricing.annualRMTPT2.toFixed(2) + "  Annual RMT + Personal Trainer – option 2 – No weight restriction (No more than 3 clients at a time when using weights. No weight restrictions. Signed Client Waivers Required)",
        value: "annualRMTPT2"
      });
    }
    if (thisPricing.sixMonthRMTPT2 !== 0) {
      rmtOptions.push({
        option:
          "$" + thisPricing.sixMonthRMTPT2.toFixed(2) + "  Six Month RMT + Personal Trainer – option 2 – No weight restriction (No more than 3 clients at a time when using weights. No weight restrictions. Signed Client Waivers Required)",
        value: "sixMonthRMTPT2"
      });
    }
    if (thisPricing.annualCrossfit !== 0) {
      rmtOptions.push({
        option: "$" + thisPricing.annualCrossfit.toFixed(2) + " Annual RMT + Certified Cross Fit Instructor",
        value: "annualCrossfit"
      });
    }
    if (thisPricing.sixMonthCrossfit !== 0) {
      rmtOptions.push({
        option:
          "$" + thisPricing.sixMonthCrossfit.toFixed(2) + " Six Month RMT + Certified Cross Fit Instructor",
        value: "sixMonthCrossfit"
      });
    }


    return !visible ? null : (
      <>
        <Card style={{ marginTop: "20px" }}>
          <Typography variant={"h5"} style={{ color: "#22292f" }}>
            Registered Massage Therapist
          </Typography>
          {/* <MultiSelectQuestion
            question={"Choose a Registered Massage Therapist policy:"}
            id={"RMT.selection"}
            path={"RMT.selection"}
            options={rmtOptions}
          /> */}
          {isProvinceNewBrunswickOrPEI?
          (<>
          <MultiSelectQuestion
            question={"Choose a Registered Massage Therapist policy:"}
            id={"RMT.selection"}
            path={"RMT.selection"}
            options={rmtOptions}
          />
          <TextQuestion
            id={"RMT.schoolRN"}
            path={"RMT.schoolRN"}
            question={
              "Please list your College of Massage therapy registration number*"
            }
          />
          <YesNoQuestion
            question={
              "Are you an active member, in good standing with your Provincial College of Massage therapists*"
            }
            info={
              "Please note you must notify us if your status changes with the College within 30 days of status change"
            }
            id={"RMT.goodStandingyesNo"}
            path={"RMT.goodStandingyesNo"}
          /></>):(isProvinceSaskatchewan?(
            <>
            <MultiSelectQuestion
              question={"Choose a Registered Massage Therapist policy:"}
              id={"RMT.selection"}
              path={"RMT.selection"}
              options={[{  option: "$" + thisPricing.annualRMT.toFixed(2) + " Annual RMT Policy",
                value: "annualRMT"}]}
            />
            <YesNoQuestion
              question={
                "Do you practice Osteopathy?"
              }
              info={
                "If yes an additional premium of $300 needs to be added"
              }
              id={"RMT.practiceOsteopathyyesNo"}
              path={"RMT.practiceOsteopathyyesNo"}
            />
            </>
          ):
          ( <>
          <MultiSelectQuestion
            question={"Choose a Registered Massage Therapist policy:"}
            id={"RMT.selection"}
            path={"RMT.selection"}
            options={rmtOptions}
          />
          <MultiSelectQuestion
            id={"RMT.school"}
            path={"RMT.school"}
            question={"Please indicate which RMT School you graduated from"}
            options={[
              {
                option: "Algonquin College",
                value: "algonquin_college"
              },
              {
                option: "Alexandrian Institue",
                value: "alexandrian_institue"
              },
              { option: "Bryan College", value: "bryan_college" },
              {
                option: "CDI College of Business",
                value: "cdi_college_of_business"
              },
              {
                option: "Technology & Healthcare ",
                value: "_technology_&_healthcare_"
              },
              { option: "College Boreal", value: "college_boreal" },
              {
                option: "Canadian College of Health Science & Tech.",
                value: "canadian_college_of_health_science_&_tech."
              },
              {
                option: "Canadian College of Massage & Hydrotherapy",
                value: "canadian_college_of_massage_&_hydrotherapy"
              },
              {
                option: "Canadian Therapeutic College",
                value: "canadian_therapeutic_college"
              },
              {
                option: "Career Canada College",
                value: "career_canada_college"
              },
              {
                option: "Centennial College",
                value: "centennial_college"
              },
              {
                option:
                  "College of Traditional Chinese Medicine and Pharmacology Canada (Toronto)",
                value:
                  "college_of_traditional_chinese_medicine_and_pharmacology_canada_(toronto)"
              },
              { option: "D'Arcy Lane", value: "d'arcy_lane" },
              { option: "Elmcrest College", value: "elmcrest_college" },
              {
                option: "Everest College of Business",
                value: "everest_college_of_business"
              },
              {
                option: "Elegance School of Esthetics",
                value: "elegance_school_of_esthetics"
              },
              { option: "Fanshawe College", value: "fanshawe_college" },
              {
                option: "Foothills College of Massage Therapy",
                value: "foothills_college_of_massage_therapy"
              },
              { option: "Georgian College", value: "georgian_college" },
              { option: "Humber College", value: "humber_college" },
              {
                option: "ICT Northumberland College",
                value: "ict_northumberland_college"
              },
              {
                option: "International Academy of Massage Therapy",
                value: "international_academy_of_massage_therapy"
              },
              {
                option: "International Academy Health Education Group",
                value: "international_academy_health_education_group"
              },
              {
                option: "Institue of Complementary & Alternative Therapies",
                value: "institue_of_complementary_&_alternative_therapies"
              },
              { option: "Kikkawa", value: "kikkawa" },
              { option: "KINE CONCEPT", value: "kine_concept" },
              {
                option: "Kawartha Centre for Integrative Health Education",
                value: "kawartha_centre_for_integrative_health_education"
              },
              { option: "Lambton College", value: "lambton_college" },
              { option: "Medix School", value: "medix_school" },
              { option: "Mohawk College", value: "mohawk_college" },
              {
                option: "National Institute",
                value: "national_institute"
              },
              {
                option: "Northumberland College",
                value: "northumberland_college"
              },
              {
                option: "Ontario Business College",
                value: "ontario_business_college"
              },
              {
                option: "Ontario College of Health & Tech.",
                value: "ontario_college_of_health_&_tech."
              },
              {
                option:
                  "Ontario School of Osteopathy ** Trains RMTs for board exams **",
                value:
                  "ontario_school_of_osteopathy_**_trains_rmts_for_board_exams_**"
              },
              { option: "Oxford College", value: "oxford_college" },
              { option: "Protégé School", value: "protégé_school" },
              {
                option: "Royal Canadian College of Massage Therapy",
                value: "royal_canadian_college_of_massage_therapy"
              },
              {
                option: "Royal Canadian Institute of Technology",
                value: "royal_canadian_institute_of_technology"
              },
              {
                option: "Royal Institute of Science & Management",
                value: "royal_institute_of_science_&_management"
              },
              { option: "Sault College", value: "sault_college" },
              {
                option: "Sir Sandford Fleming Collge",
                value: "sir_sandford_fleming_collge"
              },
              { option: "Sutherland Chan", value: "sutherland_chan" },
              {
                option: "Trillium College ",
                value: "trillium_college_"
              },
              {
                option: "Wellsprings College of Massage Therapy & Esthetics",
                value: "wellsprings_college_of_massage_therapy_&_esthetics"
              },
              {
                option: "West Coast College of Massage Therapy",
                value: "west_coast_college_of_massage_therapy"
              },
              {
                option: "Westervelt College",
                value: "westervelt_college"
              },
              {
                option:
                  "Wellsprings College of Massage Therapy and Esthetics",
                value: "wellsprings_college_of_massage_therapy_and_esthetics"
              },
              {
                option: "Westervelt College",
                value: "westervelt_college"
              },
              {
                option: "Vancouver College of Massage Therapy-  ",
                value: "vancouver_college_of_massage_therapy-__"
              },
              {
                option: "WCCMT New Westminster Campus",
                value: "wccmt_new_westminster_campus"
              },
              { option: "Langara College", value: "langara_college" },
              {
                option: "Okanagan Valley College of Massage Therapy",
                value: "okanagan_valley_college_of_massage_therapy"
              },
              {
                option: "Vancouver Career College",
                value: "vancouver_career_college"
              },
              {
                option: "Other",
                value: "other"
              }
            ]}
          />

          <TextQuestion
            id={"RMT.schoolRN"}
            path={"RMT.schoolRN"}
            question={
              "Please list your College Registration Number* (If you do\
              not have a CMTO or CMTBC registration number yet, please\
              enter your RMT student number.)"
            }
          />

          <YesNoQuestion
            question={
              "Are you an active member, in good standing with the CMTO or CMTBC?*"
            }
            info={
              "Please note you must notify us if your status changes with the College within 30 days of status change"
            }
            id={"RMT.goodStandingyesNo"}
            path={"RMT.goodStandingyesNo"}
          />
          </>))}

          {values.RMT.goodStandingyesNo === "no" ? (
            <YesNoQuestion
              question={
                "Have you applied, or are you applying, to be a member of the CMTO or CMTBC?"
              }
              info={
                "Please note you must notify us if your status changes with the College within 30 days of status change"
              }
              id={"RMT.goodStandingapplied"}
              path={"RMT.goodStandingapplied"}
            />
          ) : (
              ""
            )}
          <YesNoQuestion
            question={
              "Do you practice any of these modalities:  Cupping(Non Heat), Hot Stone, Aromatherapy, Reiki or Reflexology?"
            }
            info={
              "Please note there is no additional charge to add any of these modalities to your policy."
            }
            id={"RMT.additionalModalities"}
            path={"RMT.additionalModalities"}
          />

          {values.RMT.additionalModalities === "yes" ? (
            <UploadQuestion
                question={"Please upload your training certificate "}
                fileCollectionName={"rmtFiles"}
            />
          ) : (
              ""
            )}

          <br />

          <YesNoQuestion
            id={"RMT.extraModalities"}
            path={"RMT.extraModalities"}
            question={
              "Do you practice any additional Modalities?"
            }
            info={
              values.RMT.selection ==='sixMonthRMT'? 
                "Please note there is an additional charge of $" + thisPricing.addModalitySixMonth.toFixed(2) +" semi-annual\
                premium to add any of these modalities to your policy. This added charge is only applicable once."
                :"Please note there is an additional charge of $" + thisPricing.addModality.toFixed(2) + " annual\
                premium to add any of these modalities to your policy. This added charge is only applicable once."
            }
          />
           {/* <MultiSelectQuestion
            id={"RMT.extraModalitiesSelect"}
            path={"RMT.extraModalitiesSelect"}
            question={"Do you practice any of these Modalities"}
            options={[
                {option:"Bioenergetic Intolerance Elimination – BIE", value:"Bioenergetic Intolerance Elimination – BIE"},           
                {option:"Nordic Pole Walking", value:"Nordic Pole Walking"},
                {option:"Buteyko Breathing", value:"Buteyko Breathing"},
                {option:"Agatsu Joint Mobility", value:"Agatsu Joint Mobility"},
                {option:"Surfset Fitness", value:"Surfset Fitness"},
                {option:"Callanetics(Exercise program)", value:"Callanetics(Exercise program)"},
                {option:"Nutritional Consulting", value:"Nutritional Consulting"},
                {option:"3-D Workout", value:"3-D Workout"},
                {option:"Ageless Grace or Ageless Dance", value:"Ageless Grace or Ageless Dance"},
                {option:"Bath Therapy - aka Hydrotherapy", value:"Bath Therapy - aka Hydrotherapy"},
                {option:"Canine Massage", value:"Canine Massage"},
                {option:"Pilates", value:"Pilates"},
                {option:"TOTAL BODY MODIFICATION", value:"TOTAL BODY MODIFICATION"},
                {option:"Athletic Therapy", value:"Athletic Therapy"},
                {option:"Critical Adjustment Therapy", value:"Critical Adjustment Therapy"},
                {option:"Eden Energy Medicine", value:"Eden Energy Medicine"},
                {option:"Sleep consultant", value:"Sleep consultant"},
                {option:"Ki-Hara Resistance Stretching", value:"Ki-Hara Resistance Stretching"},
                {option:"Barre yoga or pilates", value:"Barre yoga or pilates"},
                {option:"Corrective Exercise Therapy", value:"Corrective Exercise Therapy"},
                {option:"Esthetics", value:"Esthetics"},
                {option:"Sports massage including Athletic taping & bracing", value:"Sports massage including Athletic taping & bracing"},
                {option:"La Blast (dance fitness)", value:"La Blast (dance fitness)"},
                {option:"TRX", value:"TRX"},
                {option:"Equine Massage", value:"Equine Massage"},
                {option:"Spin Instructor ", value:"Spin Instructor "},
                {option:"Kinstretch", value:"Kinstretch"},
                {option:"Bellyfit Instructor", value:"Bellyfit Instructor"},
                {option:"Fitness Instructor", value:"Fitness Instructor"},
                {option:"Watsu", value:"Watsu"},
                {option:"kangoo Jumps", value:"kangoo Jumps"},
                {option:"Body Balance", value:"Body Balance"},
                {option:"Kinesiology", value:"Kinesiology"},
                {option:"Yamuna Body Rolling", value:"Yamuna Body Rolling"},
                {option:"Surfset Fitness", value:"Surfset Fitness"},
                {option:"Watsu", value:"Watsu"},
                {option:"Life Coach", value:"Life Coach"},
                {option:"Yoga", value:"Yoga"},
                {option:"Kettlebell training", value:"Kettlebell training"},
                {option:"Body Wave Exercise", value:"Body Wave Exercise"},
                {option:"Muscle Activation Technique", value:"Muscle Activation Technique"},
                {option:"Zumba", value:"Zumba"},
                {option:"Pranassage", value:"Pranassage"},
                {option:"Bokwa", value:"Bokwa"},
                {option:"Movnat", value:"Movnat"},
                {option:"TABATA", value:"TABATA"},
                {option:"Shake your soal", value:"Shake your soal"},
                {option:"Bones for Life", value:"Bones for Life"},
            ]}
            info={
              values.RMT.selection ==='sixMonthRMT'? 
                "Please note there is an additional charge of $" + thisPricing.addModalitySixMonth.toFixed(2) +" semi-annual\
                premium to add any of these modalities to your policy. This added charge is only applicable once."
                :"Please note there is an additional charge of $" + thisPricing.addModality.toFixed(2) + " annual\
                premium to add any of these modalities to your policy. This added charge is only applicable once."
            }
            /> */}

          <br />

          {values.RMT.extraModalities === "yes" ? (
            <AutocompleteQuestion
              id="RMT.extraModalitiesList"
              path="RMT.extraModalitiesList"
              options={[
                {option:"Bioenergetic Intolerance Elimination – BIE", value:"Bioenergetic Intolerance Elimination – BIE"},           
                {option:"Nordic Pole Walking", value:"Nordic Pole Walking"},
                {option:"Buteyko Breathing", value:"Buteyko Breathing"},
                {option:"Agatsu Joint Mobility", value:"Agatsu Joint Mobility"},
                {option:"Surfset Fitness", value:"Surfset Fitness"},
                {option:"Callanetics(Exercise program)", value:"Callanetics(Exercise program)"},
                {option:"Nutritional Consulting", value:"Nutritional Consulting"},
                {option:"3-D Workout", value:"3-D Workout"},
                {option:"Ageless Grace or Ageless Dance", value:"Ageless Grace or Ageless Dance"},
                {option:"Bath Therapy - aka Hydrotherapy", value:"Bath Therapy - aka Hydrotherapy"},
                {option:"Canine Massage", value:"Canine Massage"},
                {option:"Pilates", value:"Pilates"},
                {option:"TOTAL BODY MODIFICATION", value:"TOTAL BODY MODIFICATION"},
                {option:"Athletic Therapy", value:"Athletic Therapy"},
                {option:"Critical Adjustment Therapy", value:"Critical Adjustment Therapy"},
                {option:"Eden Energy Medicine", value:"Eden Energy Medicine"},
                {option:"Sleep consultant", value:"Sleep consultant"},
                {option:"Ki-Hara Resistance Stretching", value:"Ki-Hara Resistance Stretching"},
                {option:"Barre yoga or pilates", value:"Barre yoga or pilates"},
                {option:"Corrective Exercise Therapy", value:"Corrective Exercise Therapy"},
                {option:"Esthetics", value:"Esthetics"},
                {option:"Sports massage including Athletic taping & bracing", value:"Sports massage including Athletic taping & bracing"},
                {option:"La Blast (dance fitness)", value:"La Blast (dance fitness)"},
                {option:"TRX", value:"TRX"},
                {option:"Equine Massage", value:"Equine Massage"},
                {option:"Spin Instructor ", value:"Spin Instructor "},
                {option:"Kinstretch", value:"Kinstretch"},
                {option:"Bellyfit Instructor", value:"Bellyfit Instructor"},
                {option:"Fitness Instructor", value:"Fitness Instructor"},
                {option:"Watsu", value:"Watsu"},
                {option:"kangoo Jumps", value:"kangoo Jumps"},
                {option:"Body Balance", value:"Body Balance"},
                {option:"Kinesiology", value:"Kinesiology"},
                {option:"Yamuna Body Rolling", value:"Yamuna Body Rolling"},
                {option:"Surfset Fitness", value:"Surfset Fitness"},
                {option:"Watsu", value:"Watsu"},
                {option:"Life Coach", value:"Life Coach"},
                {option:"Yoga", value:"Yoga"},
                {option:"Kettlebell training", value:"Kettlebell training"},
                {option:"Body Wave Exercise", value:"Body Wave Exercise"},
                {option:"Muscle Activation Technique", value:"Muscle Activation Technique"},
                {option:"Zumba", value:"Zumba"},
                {option:"Pranassage", value:"Pranassage"},
                {option:"Bokwa", value:"Bokwa"},
                {option:"Movnat", value:"Movnat"},
                {option:"TABATA", value:"TABATA"},
                {option:"Shake your soal", value:"Shake your soal"},
                {option:"Bones for Life", value:"Bones for Life"},
              ]}
              placeholder="Type and select all your modalities"
              question={"Specify all additional modalities"}
            />
          ) : (
              ""
            )}

          {newGrad ? (
            <>
              <YesNoQuestion
                question={
                  "Have you received your invitation to the join the college as a new member?"
                }
                id={"RMT.assoc"}
                path={"RMT.assoc"}
                value={values.RMT.assoc}
              />

              <YesNoQuestion
                question={
                  "Is this your first time applying for Registration with the CMTO or the CMTBC?"
                }
                id={"RMT.applying"}
                path={"RMT.applying"}
              />
            </>
          ) : (
              ""
            )}
       <YesNoQuestion
          id={"RMT.laser"}
          path={"RMT.laser"}
          question={"Do you offer any type of Laser therapy (Use a Laser machine)?"}
        />
      { values.RMT.laser === "yes"
        ? (<YesNoQuestion
          id={"RMT.coldOrLowLevelLasers"}
          path={"RMT.coldOrLowLevelLasers"}
          question={
          "Is this a Cold lasers or low-level lasers machine?"
          }
        />)
        : null}
        { values.RMT.coldOrLowLevelLasers === "no"
        ? (<YesNoQuestion
          id={"RMT.ownLaserMachine"}
          path={"RMT.ownLaserMachine"}
          question={
          "Do you own the Laser machine?"
          }
        />)
        : null}
        { values.RMT.ownLaserMachine === "yes"
            ? (
            <>
            <TextQuestion
                id={"RMT.laserMachineDetails"}
                path={"RMT.laserMachineDetails"}
                question={
                "Please provide us with the Type of laser machine, make, model, S/N"
                }
            />
            <TextQuestion
                id={"RMT.laserMachineAge"}
                path={"RMT.laserMachineAge"}
                question={
                "Please provide us with the age of machine, where was it manufactured & value"
                }
            />
            <TextQuestion
                id={"RMT.laserMachineMobile"}
                path={"RMT.laserMachineMobile"}
                question={
                "Please advise if this laser equipment will be used in the clinic only or in different locations / mobile"
                }
            />
            <YesNoQuestion
                id={"RMT.laserMachineWaivers"}
                path={"RMT.laserMachineWaivers"}
                question={
                "Are waivers being signed by each participant?"
                }
            />
            <TextQuestion
                id={"RMT.laserMachineMaintenance"}
                path={"RMT.laserMachineMaintenance"}
                question={
                "What is the maintenance for this equipment?"
                }
            />
            <TextQuestion
                id={"RMT.laserMachineLeasedOwned"}
                path={"RMT.laserMachineLeasedOwned"}
                question={
                "Is the equipment leased or owned by you?"
                }
            />
            <TextQuestion
                id={"RMT.laserMachineUse"}
                path={"RMT.laserMachineUse"}
                question={
                "What is the machine used for?"
                }
            />
            <YesNoQuestion
                id={"RMT.laserMachineUsedBy"}
                path={"RMT.laserMachineUsedBy"}
                question={
                "Will there be other practitioners operating the equipment?"
                }
            />
            <YesNoQuestion
                id={"RMT.laserMachineTraining"}
                path={"RMT.laserMachineTraining"}
                question={
                "Are you properly trained for operations of this laser machine?"
                }
            />
            
            </>)
        : null}
        {values.RMT.coldOrLowLevelLasers === "yes"
        ?  (<UploadQuestion
            question={"Please upload training certificate for the use of the machine"}
            fileCollectionName={"RMTLaserMachineFiles"}
           />)
        :null}
        </Card>
      </>
    );
  }
}
