import values from "./values";
import validation from "./validation";
const herbalist = {
    associationMemberyesNo: {
      defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question: "Are you a member of an association?",
      info:
        "If yes, please provide name of the association. If not, do you have 3 or more years experience."
    },
    associationName: {
        defaultvalue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        question: "Please enter the name of the association",
    },
    expierence3orMore:{
        defaultvalue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        question: "Do you have 3 or more years experience as a Herbalist?",
    },
    pillForm:{
        defaultvalue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        question:"Do you offer Herbal Medicine in a pill form?"
    },
    manufacturePills:{
        defaultvalue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        question: "Do you manufacture / repack / relabel the pills?"
    },
    manufacturePillsApprovedFacility:{
        defaultvalue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        question:"Are the Pills being Manufactured in a Canadian Government approved Facility?"
    },
    manufacturePillsTested:{
        defaultvalue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        question:"Are the Herbs being tested prior to being composed in the pill form?"
    },
    manufacturePillsPurchasedSupplier:{
        defaultvalue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        question:"Are the Herbs purchased in Canada or by a Canadian certified supplier?"   
    },
    selectedOption: {
        defaultvalue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        question: "Please choose from the following options:"
      },      
};
export default herbalist;