const initialState = {
  modalities: {
    files: [],
    names: []
  },
  osteopathyFiles: {
    files: [],
    names: []
  },
  osteopathyLaserMachineFiles:{
    files: [],
    names: []
  },
  physiotherapyFiles: {
    files: [],
    names: []
  },
  physiotherapyLaserMachineFiles:{
    files: [],
    names: []
  },
  fitenessInstructorFiles: {
    files: [],
    names: []
  },
  yogaFiles: {
    files: [],
    names: []
  },
  ncFiles: {
    files: [],
    names: []
  },
  aromatherapyFiles: {
    files: [],
    names: []
  },
  personalTrainerFiles: {
    files: [],
    names: []
  },
  kinesiologyFiles: {
    files: [],
    names: []
  },
  kinesiologyLaserMachineFiles:{
    files: [],
    names: []
  },
  acupunctureRMTPolicyFiles:{
    files: [],
    names: []
  },
  acupunctureFiles: {
    files: [],
    names: []
  },
  acupunctureFiles2: {
    files: [],
    names: []
  },
  acupunctureLaserMachineFiles:{
    files: [],
    names: []
  },
  rmtFiles: {
    files: [],
    names: []
  },
  RMTLaserMachineFiles:{
    files: [],
    names: []
  },
  acupunctureMembershipFiles:{
    files: [],
    names: []
  },
  kidsyoga: { files: [], names: [] },
  estheticsLaserMachineFiles:{
    files: [],
    names: []
  },
  microbladingCertificate:{
    files: [],
    names: []
  },
  microneedlingCertificate:{
    files: [],
    names: []
  }
};

const fileReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FILES":
      const a = {
        [action.groupName]: {
          files: action.fileSet,
          names: action.fileGroupName
        }
      };
      return { ...state, ...a };
    default:
      return state;
  }
};

export default fileReducer;
