import React from "react";
import Card from "components/Card";
import Typography from "@material-ui/core/Typography";
import {
    MultiSelectQuestion
} from "../helpers";
import { pricing } from "constants/pricing";

export default class RegisteredDietitianForm extends React.Component {
  render() {
    const { values, visible } = this.props;

    // determine if the pricing is for new business or old, and what the effective date is
    let effectiveDate = new Date().setHours(24, 0, 0, 0); // next midnight
    if (values.applicantInformation.newPolicyyes_no === "yes" && values.applicantInformation.expiring_policydifferentDate != "") {
      effectiveDate = new Date(values.applicantInformation.expiring_policydifferentDate).setHours(24, 0, 0, 0);
    }
    let thisPricing;

    // knowing the effective date, go backwards through the pricing array to find the pricing that matches
    // pricing in array is epoch time, so multiply by 1000 to get javascript version
    for (let i = pricing.length - 1; i >= 0; i--) {
      if (values.applicantInformation.newPolicyyes_no === "yes") {
        if (effectiveDate > pricing[i].effective.new * 1000) {
          thisPricing = pricing[i].registeredDietitianPrices;
          break;
        }
      } else {
        if (effectiveDate > pricing[i].effective.renewal * 1000) {
          thisPricing = pricing[i].registeredDietitianPrices;
          break;
        }
      }
    }

    return !visible ? null : (
      <Card>
        <Typography variant={"h5"} style={{ color: "#22292f" }}>
        Registered Dietitian
        </Typography>
        <hr />
        <br />
        <MultiSelectQuestion
                question={"Please choose from the following options."}
                id={"registeredDietitian.selectedOption"}
                path={"registeredDietitian.selectedOption"}
                options={[
                  {
                    option:
                        "$" + thisPricing.prof2mil.toFixed(2) + " - $2,000,000 Professional Liability Only ",
                    value: "prof2mil"
                    },
                  {
                    option:
                      "$" + thisPricing.prof2milGl.toFixed(2) + " - $2,000,000 Professional Liability and Commercial General Liability ",
                    value: "prof2milGl"
                  },
                  {
                    option:
                    "$" + thisPricing.prof5mil.toFixed(2) + " - $5,000,000 Professional Liability Only ",
                    value: "prof5mil"
                  },
                  {
                    option:
                    "$" + thisPricing.prof5milGl.toFixed(2) + " - $5,000,000 Professional Liability and Commercial General Liability ",
                    value: "prof5milGl"
                  },
                ]}
              />
      </Card>
    );
  }
}