import React from "react";
import Card from "components/Card";
import Typography from "@material-ui/core/Typography";
import { pricing } from "constants/pricing";
import  {YesNoQuestion, TextQuestion, MultiSelectQuestion } from "../helpers";

export default class Herbalist extends React.Component{
    render() {
        const { values, visible } = this.props;
    
        // determine if the pricing is for new business or old, and what the effective date is
        let effectiveDate = new Date().setHours(24, 0, 0, 0); // next midnight
        if (values.applicantInformation.newPolicyyes_no === "yes" && values.applicantInformation.expiring_policydifferentDate != "") {
          effectiveDate = new Date(values.applicantInformation.expiring_policydifferentDate).setHours(24, 0, 0, 0);
        }
        let thisPricing;
    
        // knowing the effective date, go backwards through the pricing array to find the pricing that matches
        // pricing in array is epoch time, so multiply by 1000 to get javascript version
        for (let i = pricing.length-1; i >= 0; i--) {
          if (values.applicantInformation.newPolicyyes_no === "yes") {
            if (effectiveDate > pricing[i].effective.new * 1000) {
              thisPricing = pricing[i].herbalistPrices;
              break;
            }
          } else {
            if (effectiveDate > pricing[i].effective.renewal * 1000) {
              thisPricing = pricing[i].herbalistPrices;
              break;
            }
          }
        }
    
        return !visible ? null : (
          <Card>
            <Typography variant={"h5"} style={{ color: "#22292f" }}>
              Herbalist
            </Typography>
            <hr />
            <YesNoQuestion
              id={"herbalist.associationMemberyesNo"}
              path={"herbalist.associationMemberyesNo"}
              question={"Are you a member of an association?"}
              info={
                "If yes, please provide. If not, please upload your Kinesiology diploma below."
              }
            />
            {values.herbalist.associationMemberyesNo === "yes"?
            ( <TextQuestion
                id={"herbalist.associationName"}
                path={"herbalist.associationName"}
                question={"Please enter the name of the association"}
              />
            ):
            (<YesNoQuestion
                id={"herbalist.expierence3orMore"}
                path={"herbalist.expierence3orMore"}
                question={"Do you have 3 or more years’ experience as a Herbalist?"}
              />
              )}
              {values.herbalist.expierence3orMore === "yes" || values.herbalist.associationMemberyesNo === "yes"?
              (<>
               <YesNoQuestion
                    id={"herbalist.pillForm"}
                    path={"herbalist.pillForm"}
                    question={"Do you offer Herbal Medicine in a pill form?"}
              />
              <YesNoQuestion
                id={"herbalist.manufacturePills"}
                path={"herbalist.manufacturePills"}
                question={"Do you manufacture / repack / relabel the pills?"}
              />
               <YesNoQuestion
                id={"herbalist.manufacturePillsApprovedFacility"}
                path={"herbalist.manufacturePillsApprovedFacility"}
                question={"Are the Pills being Manufactured in a Canadian Government approved Facility?"}
              />
              <YesNoQuestion
                id={"herbalist.manufacturePillsTested"}
                path={"herbalist.manufacturePillsTested"}
                question={"Are the Herbs being tested prior to being composed in the pill form?"}
              />
              <YesNoQuestion
                id={"herbalist.manufacturePillsPurchasedSupplier"}
                path={"herbalist.manufacturePillsPurchasedSupplier"}
                question={"Are the Herbs purchased in Canada or by a Canadian certified supplier?"}
              />
              <MultiSelectQuestion
                question={"Please choose from the following options."}
                id={"herbalist.selectedOption"}
                path={"herbalist.selectedOption"}
                options={[
                  {
                    option:
                      "$" + thisPricing.prof2mil.toFixed(2) + " - $2,000,000 Professional Liability and Commercial General Liability ",
                    value: "prof2mil"
                  },
                  {
                    option:
                    "$" + thisPricing.prof3mil.toFixed(2) + " - $3,000,000 Professional Liability and Commercial General Liability ",
                    value: "prof3mil"
                  },
                  {
                    option:
                    "$" + thisPricing.prof5mil.toFixed(2) + " - $5,000,000 Professional Liability and Commercial General Liability ",
                    value: "prof5mil"
                  },
                ]}
              /></>):(<></>)}
            </Card>
        );
    }
}