import React, { useEffect } from "react";
import styles from "./Home.module.css";
import RMTForm from "./forms/RMTForm";
import YogaForm from "./forms/YogaForm";
import NCForm from "./forms/NCForm";
import PTForm from "./forms/PTForm";
import ReflexologyForm from "./forms/ReflexologyForm";
import OsteopathyForm from "./forms/OsteopathyForm";
import KinesiologyForm from "./forms/KinesiologyForm";
import Physiotherapy from "./forms/Physiotherapy";
import FIForm from "./forms/FIForm";
import AromatherapyForm from "./forms/AromatherapyForm";
import ApplicantInformationForm from "./forms/ApplicantInformationForm";
import Disclaimer from "./forms/Disclaimer";
import Pilates from "./forms/Pilates";
import Crossfit from "./forms/Crossfit";
import Psychologist from "./forms/Psychologist";
import Acupuncture from "./forms/Acupuncture";
import Esthetics from "./forms/Esthetics";
import PrivacyBreach from "./forms/PrivacyBreach";
import LifeCoachForm from "./forms/LifeCoachForm";
import SleepConsultantForm from "./forms/SleepConsultantForm";
import ReikiForm from "./forms/Reiki";
import HerbalistForm from "./forms/HerbalistForm";
import RegisteredDietitianForm from "./forms/RegisteredDietitianForm";

const InsuranceApplication = props => {
  if (props.successFullSubmission) {
    props.history.push("/success");
    return null;
  }

  return (
    <div>
      <div className={styles["cardWrapper"]}>
        <ApplicantInformationForm />
      </div>
      <div className={styles["cardWrapper"]}>
        <RMTForm />
      </div>
      <div className={styles["cardWrapper"]}>
        <YogaForm />
      </div>
      <div className={styles["cardWrapper"]}>
        <NCForm />
      </div>
      <div className={styles["cardWrapper"]}>
        <PTForm />
      </div>
      <div className={styles["cardWrapper"]}>
        <ReflexologyForm />
      </div>
      <div className={styles["cardWrapper"]}>
        <OsteopathyForm />
      </div>
      <div className={styles["cardWrapper"]}>
        <KinesiologyForm />
      </div>
      <div className={styles["cardWrapper"]}>
        <Physiotherapy />
      </div>
      <div className={styles["cardWrapper"]}>
        <FIForm />
      </div>
      <div className={styles["cardWrapper"]}>
        <AromatherapyForm />
      </div>
      <div className={styles["cardWrapper"]}>
        <Pilates />
      </div>
      <div className={styles["cardWrapper"]}>
        <Crossfit />
      </div>
      <div className={styles["cardWrapper"]}>
        <Psychologist />
      </div>
      <div className={styles["cardWrapper"]}>
        <Acupuncture />
      </div>
      <div className={styles["cardWrapper"]}>
        <Esthetics />
      </div>
      <div className={styles["cardWrapper"]}>
        <LifeCoachForm />
      </div>
      <div className={styles["cardWrapper"]}>
        <SleepConsultantForm />
      </div>
      <div className={styles["cardWrapper"]}>
        <ReikiForm />
      </div>
      <div className={styles["cardWrapper"]}>
        <HerbalistForm />
      </div>
      <div className={styles["cardWrapper"]}>
        <RegisteredDietitianForm />
      </div>
      <div className={styles["cardWrapper"]}>
        <PrivacyBreach />
      </div>
      <div className={styles["cardWrapper"]}>
        <Disclaimer />
      </div>
    </div>
  );
};

export default InsuranceApplication;
